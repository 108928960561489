<template>
  <div class="task-result-main">

    <div class="task-result-box">
      <div class="task-result-icon">
        <Isvg v-if="audit_status==1" icon-class="audit-ing" />
        <Isvg v-if="audit_status==9" icon-class="audit-success" />
        <Isvg v-if="audit_status==2" icon-class="audit-fail" />
      </div>
      <div class="task-result-name">{{audit_status_name}}</div>
      <div class="task-result-memo">
        <p>{{memoArray[audit_status]}}</p>
        <p v-if="audit_status==1" >审核期内，您可以 <span class="bule" @click="toUpdate">修改</span> 您的发布需求，若有变动，也可以将该任务直接 <span class="red">关闭</span></p>
      </div>

      <div class="task-result-button">
        <button @click="toInfo">查看详情</button><button @click="toList">前往管理</button>
      </div>
    </div>

  </div>
</template>

<script>
import { getTaskAuditInfo } from '@/api/task'
export default {
  data(){
    return {
      taskId : 0,
      audit_status_name: '',
      audit_status: 1,
      audit_memo: '',

      memoArray: {
        1: '平台将在3个工作日内审核您的发布',
        2: this.audit_memo,
        9: '您的任务已经成功发布，自由职业者看到您的任务后，就可能和您取得联系！'
      }
    }
  },
  created(){
    this.taskId = this.$route.params.id
    this.loadTaskResult()
  },
  methods:{
    async loadTaskResult(){
      const res = await getTaskAuditInfo({sn:this.taskId})
      if( res.statusCode == 200 ){
        this.audit_status_name = res.data.audit_status_name
        this.audit_status = res.data.audit_status
        this.audit_memo = res.data.audit_memo
      }
    },
    toUpdate(){
      this.$router.push( '/task/update/'+this.taskId)
    },
    toInfo(){
      this.$router.push( '/task/info/'+this.taskId)
    },
    toList() {
      this.$router.push( '/home/hirer/task/list' )
    }
  }
};
</script>

<style lang="less" scoped>

.task-result-main {
  background-color: #f4f4f4;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .task-result-box {
    //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: #FFFFFF;
    width: 800px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .task-result-icon {
      margin-bottom: 20px;
      svg{
        width: 50px;
        height: 50px;
      }
    }

    .task-result-name {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .task-result-memo {
      text-align: center;
      font-size: 12px;
      color: #575757;
      line-height: 24px;
      margin-bottom: 20px;

      span {
        cursor: pointer;
      }

      .bule {
        color: #00ace6;
      }

      .red {
        color: #d4237a;
      }
    }

    .task-result-button {
      :last-child {
        margin-left: 20px;
        background-color: #ff9600;
      }
      button{
        border-radius: 4px;
        width: 150px;
        line-height: 26px;
        font-size:14px;
        color: #fff;
        background-color: #00A2E6;
      }
    }
  }
}


</style>
